// 
// _layouts.scss
// 

body[data-layout-size="boxed"] {
    background-color: $boxed-body-bg;
    #layout-wrapper {
        background-color: $body-bg;
        max-width: $boxed-layout-width;
        margin: 0 auto;
        box-shadow: $box-shadow;
    }

    #page-topbar {
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    }

    &.vertical-collpsed {
        #page-topbar {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
    }
}


// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"]{
    #page-topbar, #layout-wrapper, .footer {
        max-width: 100%;
    }
    .container-fluid, .navbar-header {
        max-width: $boxed-layout-width;
    }
}

.dropdown-submenu > label:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}