//
// _nav.scss
//
$media-desktop: "only screen and (max-width : 1024px)";
$media-tablet: "only screen and (max-width : 768px)";
$media-mobile: "only screen and (max-width : 600px)";
$media-mobile-sm: "only screen and (max-width : 480px)";

.bg-gray {
  background-color: #5f6972 !important;
}
.text-cd {
  color: #cfcfcf !important;
}
.negative-margin-20 {
  margin-top: -7px;
}
.margin-Article {
  margin-top: 25px;
  margin-left: 15px;
}
.aligncenterImportant {
  align-items: center !important;
}
.searchWidth {
  width: 249px;
}
.ToolBarDropDown {
  left: -208px !important;
  margin-top: -4px !important;
  padding: 0px !important;
}
.h-22 {
  height: 23px !important;
}
.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $font-weight-medium;
    }
  }
}
@media #{$media-mobile-sm} {
  .ToolBarDropDown {
    left: 0 !important;
    margin-top: -4px !important;
  }
}
.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $font-weight-medium;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;
    .nav-link {
      border: none;

      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -2px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      .nav-icon {
        font-size: 24px;
      }
    }
  }
}
