.loader {
    display: block;
    position: fixed;
    z-index: 1031; /* High z-index so it is on top of the page */
    top: 47%;
}

.loader-title {
    display: block;
    position: fixed;
    z-index: 1031; /* High z-index so it is on top of the page */
    top: 62%;
}

.loader-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cursor-pointer{
    cursor: pointer;
}

.low-opacity {
    opacity: 0.5;
}

.spinner-button {
    height: 15px;
    width: 15px;
}

.article-config-quantity-setting-label {
    display: flex;
    justify-content: flex-end;
}

.infinite-scroll-component {
    overflow: inherit!important;
    padding-left: 10px!important;
}
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

.dropdown-menu {
    margin-left: 0px !important;
}

.article-detail-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchable-btm-group {
    display: flex!important;
    flex: 1 1 auto!important;
}

.searchable-button-dropdown {
    display: flex!important;
    flex: 1 1 auto !important;
}

.searchable-button {
    display: flex!important;
}

.searchable-button-toggle {
    display: flex!important;
    flex: 1 1 auto;
    justify-content: space-between;
}

.searchable-button-toggle-wrapper {
    display: flex!important;
}

.searchable-button-toggle-wrapper-grow {
    display: flex!important;
    flex: 1 1 auto!important;
}

.card-header {
    position: relative;
  }

// .card-header-sub {
//     background-color: #fff!important;
// }
  
  .click-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .isRotated {
    transform: rotate(-180deg);
  }

  .react-date-picker__wrapper {
    border: 1px solid #ced4da;
    padding: 0.2rem 0.75rem;
    border-radius: 0.25rem;
  }

  .add-task-modal {
    min-width: 1000px;
    max-height: 900px;

    textarea { resize: none; }
  }

  .add-order-modal {
    min-width: 1400px;
    max-height: 900px;

    textarea { resize: none; }
  }

  .add-delivery-address-modal {
    min-width: 800px;
    max-height: 900px;

    textarea { resize: none; }
  }

  .task-detail-row {
    textarea { resize: none; }
  }

  .lang-edit-icon:hover {
    background-color: lightgray;
    cursor: pointer;
    border-radius: 30%;
  }

  .order-form-content {
    padding: 20px 15px;
  }

  .customer-logo {
    height: 100px!important;
    width: 100%!important;
    max-width: 400px!important;
    object-fit: cover!important;
    border-radius: 0px!important;
  }

  .bg-light-info {
    // background-color: #d3e8fc !important;
    // color: #67b1f3 !important;
    background-color: #7cbbf4 !important;
  }

  .bg-light-danger {
    background-color: #f78f8f !important;
  }

  .bg-light-warning {
    background-color: #f4c779 !important;
  }

  .bg-light-success {
    background-color: #63d5ac !important;
  }

  .bg-light-primary {
    background-color: #848f98 !important;
  }