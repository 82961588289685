.react-slick * {
  min-height: 0;
  min-width: 0;
}

.react-slick .slick-prev,
.react-slick .slick-next {
  background-color: rgb(187, 184, 184);
  border-radius: 10px;
}

.react-slick .fluid__instructions {
  margin-top: 30px;
}

@media (min-width: 480px) {
  .react-slick .fluid__image-container {
    margin: 40px;
  }

  .react-slick .fluid__instructions {
    margin: 0 20px;
    padding-top: 20px;
  }
}
.image-gallery-fullscreen-button {
  display: none !important;
}
.image-gallery-play-button {
  display: none !important;
}
.image-gallery-svg {
  height: 60px !important;
  width: 60px;
  opacity: 0.5 !important;
}

.product-imageWraper {
  position: relative;
  z-index: 9 !important;
}
.width-130px {
  width: 130px;
}
.width-591 {
  max-width: 591px;
}
