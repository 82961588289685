.inputDnD {
  .form-control-file {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 8em;
    outline: none;
    visibility: hidden;
    cursor: pointer;
    background-color: #c61c23;
    box-shadow: 0 0 5px solid currentColor;
    &:before {
      content: attr(data-title);
      position: absolute;
      left: 0;
      width: 100%;
      min-height: 8em;
      padding-top: 3em;
      opacity: 1;
      visibility: visible;
      text-align: center;
      border: 0.25em dashed currentColor;
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
     
    }
    &:hover {
      &:before {
        border-style: solid;
        box-shadow: inset 0px 0px 0px 0.25em currentColor;
      }
    }
  }
}

.ImageLogo {
        background-color: white;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-position: center center;
    background-image: url(blob:http://localhost:3000/b33974b2-8cea-494a-abf3-b5dccdf270d4);
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
    border: 2px solid gray;
}
.cancel-btn{
    margin-left: 15px !important;
}