label.link {
  cursor: pointer;
  color: #5b73e8;
  text-decoration: none !important;
  font-weight: 400 !important;
}

label.link:hover {
  color: #1e3ed8;
}
.float-right {
  float: right;
}

.product-box {
  background-color: white;
}

.height-100 {
  height: 100px !important;
  margin: auto 0;
}

.height-150 {
  height: 150px !important;
  margin: auto 0;
}

.height-250 {
  height: 250px !important;
  margin: auto 0;
}

.product-img {
  padding-bottom: 5%;
}

.light-tab-logo {
  max-height: 87px;
  margin-left: 10%;
}

.margin-15 {
  margin-top: 15%;
  margin-bottom: 15%;
}

.steps .nav-link {
  cursor: pointer;
}

.margin-left-0{
  margin-left:0 !important;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-1 {
  margin-left: 1%;
}

.margin-left-2 {
  margin-left: 2%;
}

.margin-left-5 {
  margin-left: 5%;
}

.margin-top-10 {
  margin-top: 10%;
}

.margin-top-5 {
  margin-top: 5%;
}

.margin-top-10-px {
  margin-top: 20px;
}

.margin-10 {
  margin: 10%;
}

.breadcrumb-margin-top {
  margin-top: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.min-height-400 {
  min-height: 400px;
}

.min-height-250 {
  min-height: 250px;
}

.font-color-grey {
  color: grey !important;
}

.dropdown-menu {
  z-index: 99999999;
}

.article-table tr {
  cursor: pointer;
}

/*.app-search {
  width:350px;
}*/

.text-align-center {
  text-align: center !important;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-top-50px {
  margin-top: 50px;
}

.margin-left-10 {
  margin-left: 10%;
}

.font-size-20 {
  font-size: 20px !important;
}

.margin-top-0 {
  margin-top: 0px;
}

.align-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.article-title {
  height: 90px;
  overflow: hidden;
}

.delete-category-row {
  background-color: #eee;
  padding: 5px;
  border-radius: 5px;
}

.margin-top-bottom-2px {
  margin-top: 2px;
  margin-bottom: 2px;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.font-weight-400 {
  font-weight: 400;
}

.margin-top-7px {
  margin-top: 7px;
}

.min-width-200 {
  width: 200px;
}

.exit-btn {
  font-size: 17.5px;
  padding: 5px;
}

.white-color {
  color: rgba(255, 255, 255, 0.6) !important;
}

.white-color::placeholder {
  color: rgba(255, 255, 255, 0.6);
  opacity: 1; /* Firefox */
}

.padding-side-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.download-link {
  color: #1e3ed8;
}

.text-align-right {
  text-align: right;
}

.certificate-thumbnail {
  padding: 0.25rem;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.font-bold {
  font-weight: 600;
}


.white-background{
  background-color: white !important;
}

.table-header-bg {
  background-color: $secondary;
  display: flex;

  @media (min-width: 851px) {
    min-height: 4rem;
  }
}

.toolbar-row {
  display: flex;
  flex: 1;
}

.page-max-width{
  max-width: 1600px;
  margin: 0;
}

.table-filter-dropdown {
  max-width: fit-content;
}

.font-color-green{
  color:darkgreen;
}

.custom-form-component{
  width: 95% !important;
  margin-left:5% !important;
}

.margin-right-5{
  margin-right: 5% !important;
}

.width-80{
  width:80%
}

.pointer-cursor{
  cursor: pointer;
}

.multi-select-label{
  padding:5px;
  margin-right: 1%;
}

.padding-right-20{
  padding-right: 20px;
}

.fixed-alert {
  position: fixed;
  top: 7%;
  right: 0;
  left: 13%;
  z-index: 9999;
}


.login-form-text-icon{
  position: absolute;
  width: 30px;
  margin-top: 9px;
}

.login-form-control{
  padding-left: 8%;
  line-height:2;
}
